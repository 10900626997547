body {
  margin: 0;
}

img {
  max-width: 100%;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}

.menu-icon {
  color: white;
}

@media only screen and (max-width: 992px) {
  .brand-logo {
    position: relative !important;
  }
}

@media only screen and (min-width: 993px) and (max-width: 1629px) {
  /* Center images vertically and add some left margin
   After a certain width, let the default materalize css take back over
   with of the cards will max out since we are within a container to keep 
   everyting centered on the page */
  .card .card-image img {
    margin: 0;
    top: 50% !important;
    transform: translateY(-50%) !important;
    margin-left: 10px;
  }
}
